<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs-v3.vue'
import DrawerEPurchasesV1 from '/~/components/drawer/views/e-purchases/drawer-e-purchases.vue'
import ArchivedPurchases from '/~/components/ewallet-menu/views/e-purchases/e-purchases-archived.vue'
import CurrentPurchases from '/~/components/ewallet-menu/views/e-purchases/e-purchases-current.vue'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'epurchases',
  components: {
    ArchivedPurchases,
    BaseAsidePage,
    CurrentPurchases,
    BaseTabs,
    BaseTab,
  },
  extends: DrawerEPurchasesV1,
  setup() {
    const { isBillPaymentsTemplate } = useProvider()

    return {
      ...DrawerEPurchasesV1.setup?.(...arguments),
      isBillPaymentsTemplate,
    }
  },
}
</script>

<template>
  <base-aside-page title="My ePurchases" no-padding>
    <template #action>
      <base-button
        v-if="!isBillPaymentsTemplate"
        icon="plain/reload"
        :class="[loading && 'animate-spin']"
        @click="updatePurchases"
      />
    </template>

    <base-tabs v-if="!isPurchasesEmpty" v-model="tab" class="h-full bg-white">
      <base-tab name="Current" value="current" keep-alive>
        <current-purchases :to="to" />
      </base-tab>
      <base-tab name="Archived" value="archived" keep-alive>
        <archived-purchases />
      </base-tab>
    </base-tabs>

    <base-state
      v-else-if="!isPurchasesLoading"
      title="You have no ePurchases"
      image="/empty/empty-epurchases.svg"
      class="min-h-full p-5"
    >
      <p>
        Access and manage your digital eGift Cards and eVouchers, ready for
        instant in-store redemption.
      </p>
    </base-state>
  </base-aside-page>
</template>
